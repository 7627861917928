import config from '@/../config.json'

export default {
  // Заказы
  ORDER_STATUS_NEW: 2,
  ORDER_STATUS_WAITING: 3,
  ORDER_STATUS_TIME_TO_COOK: 4,
  ORDER_STATUS_COOKING: 5,
  ORDER_STATUS_READY: 6,
  ORDER_STATUS_WAITING_DELIVERY: 7,
  ORDER_STATUS_DELIVERING: 8,
  ORDER_STATUS_DELIVERING_LATE: 9,
  ORDER_STATUS_DONE: 10,
  ORDER_STATUS_DONE_LATE: 11,
  ORDER_STATUS_CLOSED: 12,
  ORDER_STATUS_CANCELED: 13,
  ORDER_STATUSES_NEW: [2, 3, 4, 5, 6, 7],
  ORDER_STATUSES_ACTIVE: [8, 9],
  ORDER_STATUSES_COMPLETED: [10, 11],
  ORDER_STATUSES_CLOSED: [10, 11, 12, 13],
  ORDER_STATUSES_FOR_IIKO: [6, 7, 8, 12],
  ORDERS_UPDATE_TIME: 20,
  ORDER_UPDATE_TIME: 10,
  VERSION_UPDATE_TIME: 300,
  // eslint-disable-next-line no-undef
  APP_URL: config.APP_URL,
  API_HOST: config.API_HOST,
  PROCESS: config,
  SENTRY_DSN: config.SENTRY_DSN,
  // eslint-disable-next-line no-undef
  CENTRIFUGE_HOST: config.CENTRIFUGE_HOST,
  IIKO_HOST: config.IIKO_HOST,
  isProduction: 'production',
  PHONE_OPERATOR: '',
  // eslint-disable-next-line no-undef
  VERSION: '1.1.2',
  // eslint-disable-next-line no-undef
  USER_VERSION: 1,

  // Firebase
  FIREBASE_VAPID_KEY: config.FIREBASE_VAPID_KEY,
  FIREBASE_CONFIG: {
    apiKey: config.FIREBASE_API_KEY,
    authDomain: config.FIREBASE_AUTH_DOMAIN,
    projectId: config.FIREBASE_PROJECT_ID,
    storageBucket: config.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: config.FIREBASE_MESSAGING_SENDER_ID,
    appId: config.FIREBASE_APP_ID
  },

  PRINT_INVOICE_DRIVER: config.PRINT_INVOICE_DRIVER,
  AUTO_UPDATE_INFO_PLUGIN: config.AUTO_UPDATE_INFO_PLUGIN
}
